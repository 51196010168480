@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import '../scss/components/vidbacking';
@import '../scss/components/lightcase/lightcase.scss';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

//MIXINS
@mixin sectionpadding {
  padding: 50px 0;

  @include breakpoint(large) {
    padding: 100px 0;
  }
}

@mixin vertical-align {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

// animation element (on scroll animations)
.animation-element {
  opacity: 0;
  position: relative;

  &.slide-left {
    -webkit-transition: all 800ms ease;
    -moz-transition: all 800ms ease;
    -o-transition: all 800ms ease;
    transition: all 800ms ease;
    opacity: 0;
    -moz-transform: translate3d(-30px, 0px, 0px);
    -webkit-transform: translate3d(-30px, 0px, 0px);
    -o-transform: translate(-30px, 0px);
    -ms-transform: translate(-30px, 0px);
    transform: translate3d(-30px, 0px, 0px);

    @include breakpoint(small down) {
      transform: translate3d(0px, 30px, 0px);
      -moz-transform: translate3d(0px, 30px, 0px);
      -webkit-transform: translate3d(0px, 30px, 0px);
    }
  }

  &.slide-left-rotate {
    -webkit-transition: all 2s ease;
    -moz-transition: all 2s ease;
    -o-transition: all 2s ease;
    transition: all 2s ease;
    opacity: 0;
    -moz-transform: translate3d(-100px, 0px, 0px);
    -webkit-transform: translate3d(-100px, 0px, 0px);
    -o-transform: translate(-100px, 0px);
    -ms-transform: translate(-100px, 0px);
    transform: translate3d(-100px, 0px, 0px) rotate(200deg);

    @include breakpoint(small down) {
      transform: translate3d(0px, 100px, 0px);
      -moz-transform: translate3d(0px, 100px, 0px);
      -webkit-transform: translate3d(0px, 100px, 0px);
    }
  }

  &.slide-right {
    -webkit-transition: all 800ms ease;
    -moz-transition: all 800ms ease;
    -o-transition: all 800ms ease;
    transition: all 800ms ease;
    opacity: 0;
    -moz-transform: translate3d(30px, 0px, 0px);
    -webkit-transform: translate3d(30px, 0px, 0px);
    -o-transform: translate(30px, 0px);
    -ms-transform: translate(30px, 0px);
    transform: translate3d(30px, 0px, 0px);

    @include breakpoint(small down) {
      transform: translate3d(0px, 30px, 0px);
      -moz-transform: translate3d(0px, 30px, 0px);
      -webkit-transform: translate3d(0px, 30px, 0px);
    }
  }

  &.slide-up {
    opacity: 0;
    transform: translate3d(0px, 30px, 0px);
    -moz-transform: translate3d(0px, 30px, 0px);
    -webkit-transform: translate3d(0px, 30px, 0px);
    transition: all 600ms ease !important;
    -webkit-transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    -o-transition: all 600ms ease;
  }

  &.slide-down {
    opacity: 0;
    transform: translate3d(0px, -30px, 0px);
    -moz-transform: translate3d(0px, -30px, 0px);
    -webkit-transform: translate3d(0px, -30px, 0px);
    transition: all 600ms ease;
    -webkit-transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    -o-transition: all 600ms ease;
  }

  &.reveal-scroll {
    opacity: 0;
    transition: all 300ms ease;
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    -o-transition: all 400ms ease;

    &.reveal-second {
      transition-delay: 400ms;
    }

    &.reveal-third {
      transition-delay: 600ms;
    }
  }

  &.scale-up {
    -webkit-transition: all 800ms ease;
    -moz-transition: all 800ms ease;
    -o-transition: all 800ms ease;
    transition: all 800ms ease;
    opacity: 0;
    -moz-transform: scale(0, 0);
    -webkit-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  &.slide-left.in-view,
  &.slide-right.in-view,
  &.slide-up.in-view,
  &.slide-down.in-view,
  &.reveal-scroll.in-view {
    opacity: 1;
    -moz-transform: translate3d(0px, 0px, 0px);
    -webkit-transform: translate3d(0px, 0px, 0px);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate3d(0px, 0px, 0px);
  }

  &.slide-left-rotate.in-view {
    transform: rotate(0deg);
    opacity: 1;
  }

  &.scale-up.in-view {
    transform: scale(1, 1);
    opacity: 1;
  }
}


//GENERIC STYLES


/*
   * Let's target IE to respect aspect ratios and sizes for img tags containing SVG files
   *
   * [1] IE9
   * [2] IE10+
   */
/* 1 */
.ie9 img[src*=".svg"].svgimage {
  width: 100%;
}

/* 2 */
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  img[src*=".svg"].svgimage {
    width: 100%;
  }
}

button:focus,
button:active,
button:hover,
button:visited,
button:link {
  outline: 0;
}

/* to remove blue border in chrome */
a:focus,
a:active,
a:hover,
a:visited,
a:link {
  outline: 0;
}

/* to remove blue border in chrome */

//WORKAROUND FLASH OF UNSTYLED CONTENT
.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

body,
html {

  overflow-x: hidden;

}

body {
  position: relative;
}

//HEADINGS
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}

//DEFAULT BUTTON
a.button {
  text-transform: uppercase;
}

main {
  @include sectionpadding;
}

//COOKIEWARNING STYELS
#cookiesplease {
  background-color: get-color(secondary);
  color: white;
  z-index: 50000;

  display: block;

  .cookiesplease-decline {
    background-color: rgba(get-color(primary), .3);
    color: $white;
    font-family: 'Fjalla One', sans-serif;
    border-radius: 5px;

  }

  .cookiesplease-accept {
    background-color: white;
    color: $body-font-color;
    text-transform: uppercase;
    border-radius: 5px;
    font-family: 'Fjalla One', sans-serif;
  }
}

.cookiesplease button {
  line-height: 25px !important;
}

.cookiesplease p {
  color: white;
  text-align: center !important;
  display: block !important;
  max-width: 100% !important;
}

.cookiesplease a {
  color: white;
}

//HEADER
header {
  background-size: 0 0;
  background-color: get-color(primary);

  #headerContentWrapper {
    display: none;
  }

  &#home {
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    #overlay {
      width: 100%;
      height: 100%;
      background-color: get-color(third);
      opacity: .39;
      z-index: 0;
      position: absolute;
      left: 0;
      top: 0;
    }

    #headerContentWrapper {
      height: 90vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      #headerContent {
        width: 100%;
        max-width: 1200px;

        h1 {
          color: white;
          margin-left: 10px;
          font-size: 2em;
          line-height: 1.1;
          margin-bottom: 25px;

          @include breakpoint(medium) {
            font-size: rem-calc(60);
          }

          @include breakpoint(large) {
            font-size: rem-calc(90);
          }

          span {
            background-color: get-color(secondary);
            padding: 10px;
            margin-left: -10px;
          }
        }
      }
    }

    nav {
      padding-bottom: 15px;
      background-color: transparent;
      transition: all 1s ease;

      &.filled {
        background-color: get-color(primary);
      }

      #localeChooser {
        background-color: get-color(third);
        display: inline-block;
        padding: 5px;

        * {
          color: white;
          font-weight: $global-weight-bold;
          font-size: rem-calc(12);
        }

        .loginBtn {
          margin-left: 25px;
          padding-right: 10px;
          color: yellow;
          text-transform: uppercase;
        }
      }

      ul.menu {
        li {
          a {
            background-color: transparent;
            text-transform: uppercase;
            color: white;

            @include breakpoint(medium) {
              font-size: .7em;
            }

            @include breakpoint(large) {
              font-size: .9em;
            }

            &.button {
              color: white;
              background-color: get-color(secondary);

              @include breakpoint(medium) {
                font-size: .7em;
              }

              @include breakpoint(large) {
                font-size: .9em;
              }

              &:hover {
                color: white;
              }
            }

            &.active {
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  nav {
    padding-bottom: 15px;
    background-color: get-color(primary);
    transition: all 1s ease;

    &.filled {
      background-color: get-color(primary);
    }

    #localeChooser {
      background-color: get-color(third);
      display: inline-block;
      padding: 5px;

      * {
        color: white;
        font-weight: $global-weight-bold;
        font-size: rem-calc(12);
      }

      .loginBtn {
        margin-left: 25px;
        padding-right: 10px;
        color: yellow;
        text-transform: uppercase;
      }
    }

    ul.menu {
      li {
        a {
          background-color: transparent;
          text-transform: uppercase;
          color: white;

          @include breakpoint(medium) {
            font-size: .7em;
          }

          @include breakpoint(large) {
            font-size: .9em;
          }

          &.button {
            color: white;
            background-color: get-color(secondary);

            @include breakpoint(medium) {
              font-size: .7em;
            }

            @include breakpoint(large) {
              font-size: .9em;
            }

            &:hover {
              color: white;
            }
          }

          &.active {
            font-weight: 700;
          }
        }
      }
    }
  }

}

//HOMELINKS
#homeLinks {
  @include sectionpadding;

  @include breakpoint(large) {
    padding-bottom: 175px;
  }

  background-color: get-color(fourth);

  .linkItem {
    text-align: center;

    a.button {
      margin-top: -20px;
    }
  }
}

//HOMENEWS
#ListWrapper {
  @include sectionpadding;
  padding-top: 0;
  position: relative;

  .grid-container {
    @include sectionpadding;
    background-color: #FBFBFB;
    z-index: 800;
    padding: 25px;

    @include breakpoint(large) {
      margin-top: -100px;
      padding: 100px;
    }
  }
}


//NEWSLIST
#newsList {
  @include sectionpadding;

  .newsListItem {
    display: flex;
    flex-direction: column;
  }

  h3 {
    span {
      color: get-color(secondary);
      text-transform: uppercase;
      font-weight: 700;
      font-size: rem-calc(18);
      display: block;
    }

    //height: 100px;
  }

  img {
    width: 100%;
    height: auto;
    margin-bottom: 25px;
  }

  a.button {
    margin-top: auto;
    width: auto;
    width: 80px;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    color: get-color(primary);
    border-bottom: 2px solid get-color(secondary);
    text-align: left;
    position: relative;

    &:before {
      position: absolute;
      content: url('../img/btn_arrow.svg');
      right: -20px;
      bottom: 10px;

    }
  }
}


//PARALAX SECTION
#paralax {
  width: 100%;
  height: 500px;
  background-attachment: fixed;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  margin-top: -200px;
}

//FOOTER STYLES
footer {
  @include sectionpadding;

  #footerLogo {
    margin-bottom: 15px;

    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }

  #footerSocial {
    margin-top: 15px;

    a {
      margin-right: 15px;
    }
  }

  #footerMenu {
    a {
      display: block;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  #footerLogos {
    text-align: center;

    img {
      height: 100px;
      width: auto;
    }
  }
}

//PAGE INTRO
#pageIntro {
  width: 100%;
  height: auto;
  background-color: get-color(fourth);

  .imageWrapper {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: auto;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}

//GOOGLE MAP
#gmap {
  padding: 0 !important;
  padding-bottom: 75ps !important;

  #map {
    width: 100%;
    height: 70vh;
  }
}

.mainContentWrapper {
  padding-top: 0;
  position: relative;

  .grid-container {
    @include sectionpadding;
    background-color: #FBFBFB;
    z-index: 800;
    padding: 25px;

    @include breakpoint(large) {

      padding: 100px;
    }
  }
}

//COMPONENTS
#componentList {
  .component {
    margin-top: 25px;

    @include breakpoint(large) {
      margin-top: 100px;
    }

    margin-bottom: 0;

    &:first-of-type {
      margin-top: 100px;
    }

    &:last-of-type {
      margin-bottom: 25px;

      @include breakpoint(large) {
        margin-bottom: 175px;
        //padding-bottom: 100px;
      }
    }
  }

  .textImageComponent {
    background-color: #F2F4F5;

    h3 {
      font-size: 2.5em;
    }

    .cell {
      &:first-of-type {
        min-height: 400px;
        margin-bottom: 25px;

        @include breakpoint(medium) {
          height: auto;
          margin-bottom: 0;
        }

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }

      &:last-of-type {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include breakpoint(medium) {
          padding: 50px;
        }
      }
    }
  }

  .lighboxComponent {
    background-color: white;

    h3 {
      text-align: center;
      margin-bottom: 25px;
    }
  }

  .videoComponent {
    background-color: white;
  }

  .twoColumnComponent {
    background-color: white;
  }

}

//GALLERYPAGE
#galleryList {
  position: relative;
  z-index: 4;

  .grid-container,
  .grid {
    background-color: #FBFBFB;
  }

  .grid-sizer,
  .grid-item {
    width: 100%;

    @include breakpoint(medium) {
      width: 50%;
    }

    padding: 10px;
    padding-top: 0px;
    margin-bottom: 15px;
  }

}

//CONTACTPAGE / FORM STYLES
form {
  margin-bottom: 75px;

  label {
    font-family: $header-font-family;
    text-transform: uppercase;

  }

  input[type=submit] {
    box-shadow: none;
    background-color: get-color(secondary);
    color: white;
    border: none;
    padding: 15px;
    text-transform: uppercase;
    font-family: $header-font-family;
  }
}

.g-recaptcha {
  margin-bottom: 15px;
}

#companyList {
  margin-bottom: 75px;

  p {
    color: #707070;

    strong {
      color: #2B2A2A;
      font-weight: 600;
    }
  }
}

//NEWSARTICLE STYLES
.newsDate {
  font-family: $header-font-family;
  color: get-color(secondary);
  text-transform: uppercase;
  font-weight: 700;
  font-size: rem-calc(18);
}

//MEMBER INFO PAGE STYLES
#DownloadList {
  .documentItem {
    border:1px solid lightslategrey;
    border-radius: 10px;
    padding: 25px;
    h3 {
      font-size: 1rem;
    }
    p {
      font-size: rem-calc(13px);
      line-height: 1.2em;
    }
    a.button {
      margin-bottom: 0;
      font-size: rem-calc(13px);
      width: 100%;
    }
  }
}